export class ModulePermissionElement{
    Description:string;
    IsActive:string;
    IsActive1:string;
    IsActive2:string;
    IsModule:string;
    IsPermitted:string;
    ModuleID:string;
    ModuleID1:string;
    ModuleName:string;
    Notes:string;
    PrivilegeID:string;
    RoleName:string;
    UserRoleId:string;
    UserRoleId1:string;
}

export class ModulePermissionState{
    permission:ModulePermissionElement[];
}