// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  production: false,
 
   /////////////  api hosted locally //////////////////
     // apiUrl: 'http://localhost:63601/api'

  ////////////////   api application in debug mode ////////////
   /// apiUrl: 'http://localhost:58782/api'


  ///////////////////  api in live in client machine ////////////////
    ////apiUrl: 'http://adminapinew.thenugabest.com/api'
    apiUrl: 'https://api.thenugabest.com/api'

  
};
