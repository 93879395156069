import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AngularSidebarComponent } from './angular-sidebar.component';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';

@NgModule({
    imports: [ RouterModule, CommonModule ,NgMaterialMultilevelMenuModule],
    declarations: [ AngularSidebarComponent ],
    exports: [ AngularSidebarComponent ]
})

export class AngularSidebarModule {}
