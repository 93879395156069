import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {CompanyElement} from "../../Models/ApplicationModels/CompanyModel";

import { environment } from '../../../environments/environment';
//   private apiUrl = environment.apiUrl;

@Injectable()
export class CompanyService {

    ////import { environment } from '../../../environments/environment';
    private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  /////////////////////////  ------------  GET ALL Company ------------
  getAllCompanyData() {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/CompanyMaster/GetAllCompany",httpOptions);
  }



  ///////////////////////  ----------- GET ALL Company BY COMP_ID -----------
  getAllCompanyDataByCompId(compId:number) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/CompanyMaster/GetAllCompanyById?compid="+compId,httpOptions);
  }



  /////////////////////////////  ------------- CURD OPERATIONS -----------------
  insertCompanyData(comp:CompanyElement) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  return this.http.post(""+this.apiUrl+"/CompanyMaster/AddCompany",
  JSON.stringify(comp),
   httpOptions
  )
  }




}
