
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
//   private apiUrl = environment.apiUrl;

@Injectable()
export class LoginService {

     ////import { environment } from '../../../environments/environment';
     private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  // login(name,password,compid,branchid) {
  //   var httpOptions = {
  //     headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Credentials': 'true',
  //         'Access-Control-Allow-Headers': 'Content-Type',
  //         'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  //         'key': 'x-api-key',
  //         'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

  //     })
  // };
  // return this.http.get(`"+this.apiUrl+"/AppUserMaster/GetAllAppUserByUserPassCompBra?username=${name}&password=${password}&compid=${compid}&branchid=${branchid}`,httpOptions);

  // }



  login(data) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  return this.http.post(""+this.apiUrl+"/AppUserMaster/AddLogin", JSON.stringify(data),httpOptions);
  }



  register(data) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  return this.http.post(""+this.apiUrl+"/AppUserMaster/AddRegistration", JSON.stringify(data),httpOptions);
  }



  getPasswordReset(usernames:string) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/ApplicationEntriesDetails/GeneratePassword?username="+usernames,httpOptions);
  }




}
