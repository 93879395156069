
import { Component, OnInit } from '@angular/core';
//import { Component,  OnInit,  Input,  Output,  EventEmitter,  ViewChild,} from "@angular/core";
//import { Directive, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../Services';
import { AppUserService } from '../Services/ApplicationServices/AppUserService';
import { CompanyService } from '../Services/ApplicationServices/CompanyService';
import { BranchService } from '../Services/ApplicationServices/BranchService';
import { LoginService } from '../Services/ApplicationServices/LoginService';
import { ModulePermissionStore } from '../shared/store/modulePerm.store';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-admin-login',
    templateUrl: './admin-login.component.html',
    styleUrls: ['./admin-login.component.scss'],
    providers: [BranchService, CompanyService, LoginService, AppUserService]
})
export class AdminLoginComponent implements OnInit {

    CK: boolean = true;CK1: boolean = true;

    fieldTextType: boolean;
    fieldTextType1: boolean;fieldTextType2: boolean;

    closeResult: string;
    moveForm: FormGroup;
    moveModalReference: any;

    loginForm: FormGroup;
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string; returnUrl2: string;
    error = '';
    companies: any;
    branches: any;
    userID: any;

    showAlert:boolean=false;
    alertmsg: string;

    showAlert1:boolean=false;
    alertmsg1: string;

    //showAlert: boolean;
    userRoleId: any;
    fullName:any;

    userroleData:any;
    //alertmsg:string=null;




    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private compService: CompanyService,
        private branchService: BranchService,
        private loginService: LoginService,
        private appuserService: AppUserService,
        private modalService: NgbModal

    ) { }

    ngOnInit() {

    this.CK1 = false;

      /*
      const togglePassword = document.querySelector('#togglePassword');
      const password = document.querySelector('#password');

      togglePassword.addEventListener('click', function (e) {
        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        this.classList.toggle('fa-eye-slash');
       });

       */


      this.moveForm = new FormGroup({
        email1: new FormControl()

      });


        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            company: new FormControl(),
            branch: new FormControl(),

        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';



        this.registerForm = this.formBuilder.group({
            userFullName: new FormControl(),
            email: new FormControl(),
            phone: new FormControl(),
            username: ['', Validators.required],
            password: ['', Validators.required],
            confirmedPassword: ['', Validators.required],
            company: new FormControl(),
            branch: new FormControl(),
            userRoleId:new FormControl(),

        });

        this.getAllCompany();
        this.getAllUserRole();

    }



    toggleFieldTextType() {
     this.fieldTextType = !this.fieldTextType;
    }

    toggleFieldTextType1() {
      this.fieldTextType1 = !this.fieldTextType1;
     }

     toggleFieldTextType2() {
      this.fieldTextType2 = !this.fieldTextType2;
     }





  //   togglePassword.addEventListener('click', function (e) {
  //     // toggle the type attribute
  //     const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
  //     password.setAttribute('type', type);
  //     // toggle the eye slash icon
  //     this.classList.toggle('fa-eye-slash');
  // });

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }



    onSubmit() {

        if(  
         // this.loginForm.value.company==null || this.loginForm.value.branch==null || 
          this.loginForm.value.username=='' || this.loginForm.value.password=='' )
        {

            this.alertmsg1 = "Mandatory fields must be filled up !";
            this.showAlert1 = true;
            setTimeout(() => {
              this.showAlert1 = false;
            }, 3000);
            return;

          }else{



        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        const formData = new FormData();
        var obj = {

            //"CompId": this.loginForm.value.company,
            //"BranchId": this.loginForm.value.branch,
            "CompId": 0,     //  IN LOGIN THERE NO CHECKING FOR COMP
            "BranchId": 0,  //  IN LOGIN THERE NO CHECKING FOR BRANCH
            "UserName": this.loginForm.value.username,
            "UserPassword": this.loginForm.value.password,


        };
        formData.append('params', JSON.stringify(obj));
        this.loginService.login(obj)

        //)
        .subscribe((data: any) => {
            if (data.length > 0) {
                this.userID = data[0].UserId;
                //  this.userRoleId = data[0].UserRoleId;
                this.userRoleId = data[0].UserRoleIdActive;
                this.fullName=data[0].UserFullName;
                if (true) {

                       // navigator.geolocation.getCurrentPosition((position) => {
                      //     const longitude = position.coords.longitude;
                     //     const latitude = position.coords.latitude;
                    //     this.callApi();
                  // });

                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                      const longitude = position.coords.longitude;
                      const latitude = position.coords.latitude;
                      sessionStorage.setItem(
                        "LongitudeLatitude",
                        longitude + " " + latitude
                      );
                    });
                  }

                this.callApi();
                } else {
                    console.log("No support for geolocation")
                    this.error = "No support for geolocation";
                    this.loading = false;
                }

            }
            else
            {
                this.alertmsg1 = "Invalid Credentials";
                this.showAlert1 = true;
                setTimeout(() => {
                  this.showAlert1 = false;
                }, 3000);

                this.loading = false;

            }

            });


          }

    }

    onRegister() {


        //      this.alertmsg="Contact Administartor !";
        //     this.showAlert=true;
        //    setTimeout(() => {
        //   this.showAlert=false;
        //  }, 2000);
        // return;

        if( 
          //this.registerForm.value.company==null || this.registerForm.value.branch==null || 
          this.registerForm.value.username=='' || this.registerForm.value.password=='' || 
          this.registerForm.value.confirmedPassword=='' ){

            this.alertmsg = "Mandatory fields must be filled up !";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
            }, 3000);
           // return;

          }
          else if( this.registerForm.value.password != this.registerForm.value.confirmedPassword )
          {

            this.alertmsg = "Password & Confirm Password is mismatched !";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
            }, 3000);
            //return;

          }
          else
          {


            const formData = new FormData();
            var obj = {

                //"UserRoleId": this.registerForm.value.userRoleId,
                "UserRoleId": 9,
                 //"CompId": this.registerForm.value.company,
                //"BranchId": this.registerForm.value.branch,
                "CompId": 1,
                "BranchId": 1,
                "UserFullName": "",
                "UserName": this.registerForm.value.username,
                "UserPassword": this.registerForm.value.password,
                "UserMail": "",
                "UserContactNO": "",
                "OperationType": "INSERT"


            };
            formData.append('params', JSON.stringify(obj));
            this.loginService.register(obj)
                .subscribe((event: any) => {
                    // if (event && event.length > 0 && event[0].OperationStatus == "Successfully Saved") {
                    if ( event && event.length > 0 ) {
                        console.log(event);
                        this.loading = false;
                        this.alertmsg =event[0].OperationStatus;
                        this.showAlert = true;
                        setTimeout(() => {
                            this.showAlert = false;
                        }, 3000);


                    }
                });

          }


    }



    callApi() {


        //Storing in session variable: USerID will replaced by user id after logging in :to do by developers
        sessionStorage.setItem("UID#LOC", this.userID + "#LongitudeLatitude");
        sessionStorage.setItem("LoggedInId", this.userID);
        sessionStorage.setItem("LoggedInRoleId", this.userRoleId);
        sessionStorage.setItem("Name", this.fullName);
        //var value=sessionStorage.getItem("UID#LOC");
        this.router.navigate([this.returnUrl]);

    }
    getAllCompany() {


        this.compService.getAllCompanyData()
            .subscribe(
                (data) => {
                    //var companies=data;
                    this.companies = data;
                    //   this.ELEMENT_DATA = data;
                    //   this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                    //   this.dataSource.paginator = this.paginator;
                    //   this.dataSource.sort = this.sort;
                }

            );
    }
    onBranchSelected(data) { }
    onCompanySelected(data) {

        this.branchService.getAllBranchDataByCompId(data).subscribe(
            (data) => {

                this.branches = data;

            }

        );
    }

    getAllUserRole() {

        //  this.shareService.getAllAppUserCategoryData()
        this.appuserService.getAllUserCategoryLatest()
            .subscribe(
              (dataxx) => {

                this.userroleData = dataxx;

              }

            );

    }

    openMoveModal1(content) {
      this.moveForm.reset();
      this.moveModalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
      this.moveModalReference.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });


    }


    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
       return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
       return 'by clicking on a backdrop';
      } else {
        return  `with: ${reason}`;
     }
    }


  onMoveInfoSubmit(){

    if( this.loginForm.value.username=='' ){

      this.alertmsg1 = "Enter Username i.e. Registered E-mail !!";
      this.showAlert1 = true;
      setTimeout(() => {
        this.showAlert1 = false;
      }, 3000);
     return;

    }


    this.loginService.getPasswordReset(this.loginForm.value.username)
      .subscribe((event: any) => {
        if (event.length > 0) {


        }
      });

    this.moveModalReference.close();

     this.alertmsg1 = "New Password will be send shortly to your registered E-mail !";
     this.showAlert1 = true;
     setTimeout(() => {
     this.showAlert1 = false;
     }, 5000);


  }






}
