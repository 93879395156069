
import { Component, OnInit } from '@angular/core';
import { RouteInfo } from '../shared/Models/RouteInfo';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  routes: RouteInfo[] = [


    { path: '/admin/applicationmaintenance', title: 'Application Maintenance', icon: 'nc-bank', class: '' },
    { path: '/admin/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '' },
    { path: '/admin/userRegistration', title: 'User Registration', icon: 'nc-diamond', class: '' },
    { path: '/admin/productUnit', title: 'Product Unit', icon: 'nc-pin-3', class: '' },
    { path: '/admin/debtors', title: 'Debtors', icon: 'nc-bell-55', class: '' },
    { path: '/admin/productCategory', title: 'Product Category', icon: 'nc-single-02', class: '' },
    { path: '/admin/productMaster', title: 'Product Master', icon: 'nc-tile-56', class: '' },
    { path: '/admin/productentries', title:'Product Entries',icon:'nc-tile-56',class:''},
    { path: '/admin/warehouseMaster', title: 'Warehouse Master', icon: 'nc-caps-small', class: '' },
    { path: '/admin/branch', title: 'Branch', icon: 'nc-spaceship', class: 'active-pro' },
    { path: '/admin/company', title: 'Company', icon: 'nc-spaceship', class: 'active-pro' },
    { path: '/admin/userPermission', title: 'User Permission', icon: 'nc-spaceship', class: 'active-pro' },
    { path: '/admin/creditors', title:'Creditors',icon:'',class:''},
    { path: '/admin/bankhead', title:'Bank Head',icon:'',class:''},
    { path: '/admin/employees', title:'Employees',icon:'',class:''},

    // { path: '/admin/stockin', title:'Stock In',icon:'',class:''},
    // { path: '/admin/stockInward', title:'Stock Inward',icon:'',class:''},
     { path: '/admin/stockMovement', title:'Stock Movement',icon:'',class:''},

    { path: '/admin/daybook', title:'Daybook',icon:'',class:''},
    { path: '/admin/employeePayroll', title:'Empliyee Payroll',icon:'',class:''},
    { path: '/admin/employeeAttendance', title:'Employee Attendance',icon:'',class:''},

    { path: '/admin/supplierDB', title:'Supplier Database',icon:'',class:''},
    { path: '/admin/customerDB', title:'Customer Databse',icon:'',class:''},

    { path: '/admin/generatedInvoice', title:'Generated Invoice',icon:'',class:''},
    { path: '/admin/daybookReport', title:'Daybook Report',icon:'',class:''},

    { path: '/admin/reviewPost', title:'Review Post',icon:'',class:''},
    { path: '/admin/productentriesAddon', title:'Product Entries Addon Info',icon:'',class:''},

    { path: '/admin/binmaster', title:'Bin Master',icon:'',class:''},

    // { path: '/admin/orderRequisition', title:'Order Requisition',icon:'',class:''},
    { path: '/admin/returnRequisition', title:'Return Order',icon:'',class:''},


    // { path: 'testpanel', component: TestPanelComponent },
    { path: '/admin/testpanel', title:'Test Panel',icon:'',class:''},

    { path: '/admin/stockin', title:'Stock In Data',icon:'',class:''},
    { path: '/admin/stockingateway', title:'Stock In Gateway',icon:'',class:''},
    { path: '/admin/stockout', title:'Stock Out Data',icon:'',class:''},
    { path: '/admin/stockoutgateway', title:'Stock Out Gateway',icon:'',class:''},
    { path: '/admin/producttransition', title:'Stock In Hand Data',icon:'',class:''},

    { path: '/admin/stockinReport', title:'Stock In Report',icon:'',class:''},
    { path: '/admin/stockoutReport', title:'Stock Out Report',icon:'',class:''},
    { path: '/admin/stockTrasnsitionReport', title:'Stock Statistics Report',icon:'',class:''},

    { path: '/admin/dealofthedayPanel', title:'Deal Of The Day',icon:'',class:''},

    { path: '/admin/reportstockinwards', title:'Inwards Report',icon:'',class:''},
    { path: '/admin/reportstockoutwards', title:'Outwards Report',icon:'',class:''},

    { path: '/admin/reportproductinhand', title:'Stock Report',icon:'',class:''},
    { path: '/admin/reportproduct', title:'Product Report',icon:'',class:''},
    { path: '/admin/reportstock', title:'Stock Report',icon:'',class:''},
    { path: '/admin/reportorder', title:'Order Report',icon:'',class:''},
    { path: '/admin/testautocomplete', title:'Auto Complete',icon:'',class:''},
    //  { path: 'testautocomplete', component: TestmultiautocompleComponent },
  
    { path: '/admin/registerappliancesequipment', title:'Appliances/Equipment',icon:'',class:''},
    { path: '/admin/registerasset', title:'Asset',icon:'',class:''},
    { path: '/admin/registerassessories', title:'Assessories',icon:'',class:''},
    

    { path: '/admin/orderpending', title:'Pending Orders',icon:'',class:''},
    { path: '/admin/orderunshipped', title:'Unshipped Orders',icon:'',class:''},
    { path: '/admin/ordercancelled', title:'Cancelled Orders',icon:'',class:''},
    { path: '/admin/orderdispatched', title:'Dispatched Orders',icon:'',class:''},
    { path: '/admin/orderdelivered', title:'Delivered Orders',icon:'',class:''},


    { path: '/admin/consignormaster', title:'Consignor',icon:'',class:''},
    { path: '/admin/consigneemaster', title:'Consignee',icon:'',class:''},

    { path: '/admin/consignormaster', title:'Consignor',icon:'',class:''},
    { path: '/admin/consigneemaster', title:'Consignee',icon:'',class:''},

    { path: '/admin/stockproductrequisition', title:'Product Requisition',icon:'',class:''},
    { path: '/admin/dealofthedaysetup', title:'Dealoftheday Setup',icon:'',class:''},

    { path: '/admin/productrequisition', title:'Product Requisition',icon:'',class:''},
    { path: '/admin/productaddonsettings', title:'Product Addon Settings',icon:'',class:''},

    { path: '/admin/ApplicationForm', title:'Application Form',icon:'',class:''},
    { path: '/admin/Ledgerprivacysetup', title:'Ledger Privacy Setup',icon:'',class:''},

    //    { path: 'Ledgerprivacysetup', component: MasterLedgerprivacysetupComponent },
    //    { path: 'Registrationinquirer', component: RegisterinquirerComponent },

    { path: '/admin/Registrationinquirer', title:'Registration Inquirer',icon:'',class:''},
    { path: '/admin/Registrationactive', title:'Active List',icon:'',class:''},


    { path: '/admin/Registrationappointments', title:'Registration Appointments',icon:'',class:''},
    { path: '/admin/Registrationqueue', title:'Registration Queue',icon:'',class:''},

    { path: '/admin/Registrationapplication', title:'Registration Application',icon:'',class:''},
    { path: '/admin/Registrationdoctor', title:'Registration Doctor',icon:'',class:''},
    { path: '/admin/Registrationassociate', title:'Registration Associate',icon:'',class:''},

    { path: '/admin/Registrationcustomer', title:'Registration Customer',icon:'',class:''},
    { path: '/admin/Registrationdebtor', title:'Registration Debtor',icon:'',class:''},
    { path: '/admin/Registrationcreditor', title:'Registration Creditor',icon:'',class:''},
    { path: '/admin/Registrationemployee', title:'Registration Employee',icon:'',class:''},
    { path: '/admin/Registrationbank', title:'Registration Bank',icon:'',class:''},
    { path: '/admin/Registrationexpenditure', title:'Registration Expenditure',icon:'',class:''},
    { path: '/admin/Registrationincome', title:'Registration Income',icon:'',class:''},
    { path: '/admin/RegisterDayBook', title:'Register Day Book',icon:'',class:''},
    { path: '/admin/RegisterPurchaseBook', title:'Register Purchase Book',icon:'',class:''},
    { path: '/admin/RegisterSalesBook', title:'Register Sales Book',icon:'',class:''},
    { path: '/admin/PurchaseBookPayment', title:'Purchase Book Payment',icon:'',class:''},
    { path: '/admin/PointOFSalePanel-2', title:'Point OF Sale Panel - 2',icon:'',class:''},

    { path: '/admin/DaybookMoneyReceive', title:'Money Receive',icon:'',class:''},
    { path: '/admin/DaybookPaymentDisburse', title:'Payment Disburse',icon:'',class:''},
    { path: '/admin/DaybookStatistics', title:'Day book Statistics',icon:'',class:''},

    { path: '/admin/productTransitionMovement', title:'Stock Transition Movement',icon:'',class:''},
    { path: '/admin/ProductRequisiton', title:'Product Requisition',icon:'',class:''},

    { path: '/admin/Slabentries', title:'Slab Entries',icon:'',class:''},
    { path: '/admin/SlabMapping', title:'Slab Mapping',icon:'',class:''},
    { path: '/admin/PointOFSalePanel-3', title:'Point OF Sale Panel - 3',icon:'',class:''},
    { path: '/admin/GenerateStatement', title:'Generate Statement',icon:'',class:''},

    { path: '/admin/GenerateStatementBeforePaid', title:'Generate Statement Before Paid',icon:'',class:''},
    { path: '/admin/GenerateStatementAfterPaid', title:'Generate Statement After Paid',icon:'',class:''},

    // { path: 'GenerateStatementBeforePaid', component: GeneratestatementBeforePaidComponent },
    // { path: 'GenerateStatementAfterPaid', component: GeneratestatementAfterPaidComponent },
    
    { path: '/admin/ParameterEntries', title:'Parameter Entries',icon:'',class:''},
    { path: '/admin/ReportPrint', title:'Report Print',icon:'',class:''},

    { path: '/admin/PointOFSalePanel-1', title:'Bill New',icon:'',class:''},

    //      { path: 'RegisterOPDBook', component: RegisteropdbookComponent },

    { path: '/admin/RegisterOPDBook', title:'Register OPD',icon:'',class:''},

    { path: '/admin/GenerateReport', title:'Generate Report',icon:'',class:''},

    { path: '/admin/GenerateReportBlood', title:'Generate Report Blood',icon:'',class:''},
    { path: '/admin/GenerateReportXray', title:'Generate Report Xray',icon:'',class:''},
    { path: '/admin/GenerateReportUsg', title:'Generate Report Usg',icon:'',class:''},
    { path: '/admin/GenerateReportEcho', title:'Generate Report Echo',icon:'',class:''},

    // { path: 'GenerateReportBlood', component: GenerateTestReportBloodComponent },
    // { path: 'GenerateReportXray', component: GenerateTestReportXrayComponent },
    // { path: 'GenerateReportUsg', component: GenerateTestReportUsgComponent },
    // { path: 'GenerateReportEcho', component: GenerateTestReportEchoComponent },

    //    { path: 'Registrationvisitor', component: RegistrationvisitorComponent },

    { path: '/admin/Registrationvisitor', title:'Registration Visitor',icon:'',class:''},


    { path: '/admin/Deletedapplications', title:'Deleted Summary',icon:'',class:''},
    { path: '/admin/EditProfile', title: 'Edit Profile', icon: 'nc-diamond', class: '' },

    { path: '/admin/BugFixing', title: 'Bug Fixing', icon: 'nc-diamond', class: '' },
    { path: '/admin/AccessRules', title: 'Access Rules', icon: 'nc-diamond', class: '' },
    { path: '/admin/RulesOfVisibility', title: 'Visibility Rules', icon: 'nc-diamond', class: '' },
    { path: '/admin/RulesOfBlock', title: 'Block Alert', icon: 'nc-diamond', class: '' },


    { path: '/admin/DueBillApplications', title: 'Due Bills', icon: 'nc-diamond', class: '' },


    // { path: '/admin/registersellablecollection', title: 'Due Bills', icon: 'nc-diamond', class: '' },
    // { path: '/admin/registerstockoutsellable', title: 'Due Bills', icon: 'nc-diamond', class: '' },
    // { path: '/admin/registerstockoutnonsellable', title: 'Due Bills', icon: 'nc-diamond', class: '' },

    { path: '/admin/registersellablecollection', title: 'Sellable', icon: 'nc-diamond', class: '' },
    { path: '/admin/registerstockoutsellable', title: 'Stock Out Sellable', icon: 'nc-diamond', class: '' },
    { path: '/admin/registerstockoutnonsellable', title: 'Stock Out Non Sellable', icon: 'nc-diamond', class: '' },


    { path: '/admin/export-printreportFailed', title: 'Failed To Generate Report', icon: 'nc-diamond', class: '' },

    { path: '/admin/export-printreportxray', title: 'Generate Report X-RAY', icon: 'nc-diamond', class: '' },
    { path: '/admin/export-printreportblood', title: 'Generate Report BLOOD', icon: 'nc-diamond', class: '' },
    { path: '/admin/export-printreportusg', title: 'Generate Report USG', icon: 'nc-diamond', class: '' },
    { path: '/admin/export-printreportecho', title: 'Generate Report ECHO', icon: 'nc-diamond', class: '' },

    { path: '/admin/export-generatebill', title: 'Generate Bill', icon: 'nc-diamond', class: '' },
    { path: '/admin/export-generatemoneyreceipt', title: 'Generate Money Receipt', icon: 'nc-diamond', class: '' },


    { path: '/admin/generate-potentiallist', title: 'Generate Potential List', icon: 'nc-diamond', class: '' },

    { path: '/admin/generate-attendancesheet', title: 'Generate Attendance Sheet', icon: 'nc-diamond', class: '' },
    { path: '/admin/generate-attendancesheetNEW', title: 'Generate Attendance Sheet NEW', icon: 'nc-diamond', class: '' },
    { path: '/admin/generate-attendancesheetfromDevice', title: 'Generate Attendance Device Output', icon: 'nc-diamond', class: '' },

    { path: '/admin/generate-attendanceEMPsheetfromDevice', title: 'Employee Attendance Device Output', icon: 'nc-diamond', class: '' },


      //   { path: 'generate-attendanceEMPsheetfromDevice', component: GenerateAttendancefromdeviceEMPComponent },

    //    { path: 'generate-attendancesheetfromDevice', component: GenerateAttendancefromdeviceComponent },
    //    { path: 'generate-attendancesheetNEW', component: AttendanceSheetNEWComponent },

    { path: '/admin/master-coupon', title: 'Coupon', icon: 'nc-diamond', class: '' },
    { path: '/admin/master-earningpoints', title: 'Earning Points', icon: 'nc-diamond', class: '' },
    { path: '/admin/master-redeempoints', title: 'RedeemPoints', icon: 'nc-diamond', class: '' },
    { path: '/admin/requisitionlist-redeempoints', title: 'Requisition List RedeemPoints', icon: 'nc-diamond', class: '' },

    { path: '/admin/generatelist-new', title: 'Generate List New', icon: 'nc-diamond', class: '' },
    { path: '/admin/generatelist-performance', title: 'Generate List Performance', icon: 'nc-diamond', class: '' },

    { path: '/admin/generatelist-feedback', title: 'Generate Feedback List', icon: 'nc-diamond', class: '' },
      //  { path: 'generatelist-feedback', component: GeneratelistFeedbackComponent },

    //   { path: 'registration-booking', component: RegistrationBookingComponent },

    { path: '/admin/registration-booking', title: 'Booking Panel', icon: 'nc-diamond', class: '' },



    { path: '/admin/payroll-masters', title: 'Payroll-Msters', icon: 'nc-diamond', class: '' },
    { path: '/admin/payroll-setup', title: 'Payroll-Setup', icon: 'nc-diamond', class: '' },
    { path: '/admin/payroll-payment', title: 'Payroll-Payment', icon: 'nc-diamond', class: '' },
    { path: '/admin/payroll-disburse', title: 'Payroll-Disburse', icon: 'nc-diamond', class: '' },
    { path: '/admin/payroll-approvalsettings', title: 'Payroll-Approval', icon: 'nc-diamond', class: '' },


    { path: '/admin/notification-email', title: 'Notification-Email', icon: 'nc-diamond', class: '' },
    { path: '/admin/notification-sms', title: 'Notification-Sms', icon: 'nc-diamond', class: '' },
    { path: '/admin/notification-bday', title: 'Notification-Bday', icon: 'nc-diamond', class: '' },
    { path: '/admin/notification-paymentreminder', title: 'Notification-Payment Reminder', icon: 'nc-diamond', class: '' },
     
  

    { path: '/admin/Page-UnderDevelopment', title: 'Page-UnderDevelopment', icon: 'nc-diamond', class: '' },
   
    //////  { path: 'Page-UnderDevelopment', component: PageUnderdevelopmentComponent },
    /////////////  11111111111111


  ];
  constructor() { }
  appitems = [
    {
      label: 'Item 1 (with Font awesome icon)',
      faIcon: 'fab fa-500px',
      items: [
        {
          label: 'Item 1.1',
          link: '/item-1-1',
          faIcon: 'fab fa-accusoft'
        },
        {
          label: 'Item 1.2',
          faIcon: 'fab fa-accessible-icon',
          items: [
            {
              label: 'Item 1.2.1',
              link: '/item-1-2-1',
              faIcon: 'fas fa-allergies'
            },
            {
              label: 'Item 1.2.2',
              faIcon: 'fas fa-ambulance',
              items: [
                {
                  label: 'Item 1.2.2.1',
                  link: 'item-1-2-2-1',
                  faIcon: 'fas fa-anchor'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      label: 'Item 2',
      icon: 'alarm',
      items: [
        {
          label: 'Item 2.1',
          link: '/item-2-1',
          icon: 'favorite'
        },
        {
          label: 'Item 2.2',
          link: '/item-2-2',
          icon: 'favorite_border'
        }
      ]
    },
    {
      label: 'Item 3',
      link: '/item-3',
      icon: 'offline_pin'
    },
    {
      label: 'Item 4',
      link: '/item-4',
      icon: 'star_rate',
      hidden: true
    }
  ];

  config = {
    paddingAtStart: true,
    classname: 'my-custom-class',
    listBackgroundColor: 'rgb(208, 241, 239)',
    fontColor: 'rgb(8, 54, 71)',
    backgroundColor: 'rgb(208, 241, 239)',
    selectedListFontColor: 'red',
  };
  ngOnInit() {
  }

}
