import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModulePermissionStore } from '../shared/store/modulePerm.store';
import { ModulePermissionElement } from '../shared/Models/ModulePermission.model';
import { AppUserService } from '../Services/ApplicationServices/AppUserService';


// export interface RouteInfo {
//     path: string;
//     title: string;
//     icon: string;
//     class: string;
// }

export const ROUTES = [

    { path: '/admin/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '' },
    { path: '/admin/userRegistration', title: 'User Registration', icon: 'nc-diamond', class: '' },
    { path: '/admin/productUnit', title: 'Product Unit', icon: 'nc-pin-3', class: '' },
    { path: '/admin/debtors', title: 'Debtors', icon: 'nc-bell-55', class: '' },
    { path: '/admin/productCategory', title: 'Product Category', icon: 'nc-single-02', class: '' },
    // { path: '/admin/productMaster', title: 'Product Master', icon: 'nc-tile-56', class: '' },
    { path: '/admin/productentries', title: 'Product Entries', icon: 'nc-tile-56', class: '' },

    { path: '/admin/warehouseMaster', title: 'Warehouse Master', icon: 'nc-caps-small', class: '' },
    { path: '/admin/branch', title: 'Best House', icon: 'nc-spaceship', class: 'active-pro' },
    { path: '/admin/company', title: 'Organization', icon: 'nc-spaceship', class: 'active-pro' },
    { path: '/admin/userPermission', title: 'User Permission', icon: 'nc-spaceship', class: 'active-pro' },
    { path: '/admin/creditors', title: 'Creditors', icon: '', class: '' },
    { path: '/admin/bankhead', title: 'Bank Head', icon: '', class: '' },
    { path: '/admin/employees', title: 'Employees', icon: '', class: '' },
    // { path: '/admin/stockin', title: 'Stock In', icon: '', class: '' },
    { path: '/admin/stockInward', title: 'Stock Inward', icon: '', class: '' },
    { path: '/admin/stockMovement', title: 'Stock Movement', icon: '', class: '' },
    { path: '/admin/orderRequisition', title: 'Order Requisition', icon: '', class: '' },
    { path: '/admin/returnRequisition', title: 'Return Requisition', icon: '', class: '' },
    { path: '/admin/daybook', title: 'Daybook', icon: '', class: '' },
    { path: '/admin/employeePayroll', title: 'Employee Payroll', icon: '', class: '' },
    { path: '/admin/supplierDB', title: 'Supplier Database', icon: '', class: '' },
    { path: '/admin/customerDB', title: 'Customer Databse', icon: '', class: '' },

    { path: '/admin/generatedInvoice', title: 'Generated Invoice', icon: '', class: '' },
    { path: '/admin/daybookReport', title: 'Daybook Report', icon: '', class: '' },


    { path: '/admin/reviewPost', title: 'Review Post', icon: '', class: '' },
    { path: '/admin/productentriesAddon', title: 'Product Entries Addon', icon: '', class: '' },

    { path: '/admin/binmaster', title: 'Bin Master', icon: '', class: '' },

    { path: '/admin/stockin', title:'Stock In', icon: '', class: '' },
    { path: '/admin/stockingateway', title:'Stock In Gateway', icon: '', class: '' },
    { path: '/admin/stockout', title:'Stock Out', icon: '', class: '' },
    { path: '/admin/stockoutgateway', title:'Stock Out Gateway', icon: '', class: '' },
    { path: '/admin/producttransition', title:'Stock Statistics', icon: '', class: '' },

    { path: '/admin/stockinReport', title: 'Stock In Report', icon: '', class: '' },
    { path: '/admin/stockoutReport', title: 'Stock Out Report', icon: '', class: '' },
    { path: '/admin/stockTrasnsitionReport', title: 'Stock Statistics Report', icon: '', class: '' },

    { path: '/admin/reportstockinwards', title:'Inwards Report',icon:'',class:''},
    { path: '/admin/reportstockoutwards', title:'Outwards Report',icon:'',class:''},
    { path: '/admin/reportproductinhand', title:'Stock Report',icon:'',class:''},
    { path: '/admin/reportproduct', title:'Product Report',icon:'',class:''},
    { path: '/admin/reportstock', title:'Stock Report',icon:'',class:''},
    { path: '/admin/reportorder', title:'Order Report',icon:'',class:''},
    { path: '/admin/testautocomplete', title:'Auto Complete',icon:'',class:''},

    { path: '/admin/orderpending', title:'Pending Orders',icon:'',class:''},
    { path: '/admin/orderunshipped', title:'Placed[Unshipped] Orders',icon:'',class:''},
    { path: '/admin/ordercancelled', title:'Cancelled Orders',icon:'',class:''},
    { path: '/admin/orderdispatched', title:'Dispatched Orders',icon:'',class:''},
    { path: '/admin/orderdelivered', title:'Delivered Orders',icon:'',class:''},


    { path: '/admin/consignormaster', title:'Consignor Billing Address',icon:'',class:''},
    { path: '/admin/consigneemaster', title:'Consignor Shipping Address',icon:'',class:''},


    { path: '/admin/stockproductrequisition', title:'Product Requisition',icon:'',class:''},
    { path: '/admin/dealofthedaysetup', title:'Dealoftheday Setup',icon:'',class:''},

    { path: '/admin/productrequisition', title:'Product Requisition',icon:'',class:''},

    { path: '/admin/productaddonsettings', title:'ProAddon Permission',icon:'',class:''},

    { path: '/admin/testpanel', title:'Test Panel',icon:'',class:''},

    { path: '/admin/ApplicationForm', title:'Application Form',icon:'',class:''},


    
    { path: '/admin/Registrationdoctor', title:'Registration Doctor',icon:'',class:''},
    { path: '/admin/Registrationassociate', title:'Registration Associate',icon:'',class:''},

    { path: '/admin/Registrationinquirer', title:'Registration Inquirer',icon:'',class:''},
    { path: '/admin/Registrationcustomer', title:'Registration Guest',icon:'',class:''},
    { path: '/admin/Registrationdebtor', title:'Registration Debtor',icon:'',class:''},
    { path: '/admin/Registrationcreditor', title:'Registration Creditor',icon:'',class:''},
    { path: '/admin/Registrationemployee', title:'Registration Employee',icon:'',class:''},
    { path: '/admin/Registrationbank', title:'Registration Bank',icon:'',class:''},
    { path: '/admin/Registrationexpenditure', title:'Registration Expenditure',icon:'',class:''},
    { path: '/admin/Registrationincome', title:'Registration Income',icon:'',class:''},
    { path: '/admin/RegisterDayBook', title:'Register Day Book',icon:'',class:''},
    { path: '/admin/RegisterPurchaseBook', title:'Register Purchase Book',icon:'',class:''},
    { path: '/admin/RegisterSalesBook', title:'Register Sales Book',icon:'',class:''},
    { path: '/admin/PurchaseBookPayment', title:'Purchase Book Payment',icon:'',class:''},
    { path: '/admin/PointOFSalePanel-2', title:'Point OF Sale Panel - 2',icon:'',class:''},
    
    { path: '/admin/DaybookMoneyReceive', title:'Money Receive',icon:'',class:''},
    { path: '/admin/DaybookPaymentDisburse', title:'Payment Disburse',icon:'',class:''},
    { path: '/admin/DaybookStatistics', title:'Day book Statistics',icon:'',class:''},

    
    { path: '/admin/productTransitionMovement', title:'Stock Transition Movement',icon:'',class:''},
    { path: '/admin/ProductRequisiton', title:'Product Requisition',icon:'',class:''},

     { path: '/admin/Slabentries', title:'Slab Entries',icon:'',class:''},
     { path: '/admin/SlabMapping', title:'Slab Mapping',icon:'',class:''},
     { path: '/admin/PointOFSalePanel-3', title:'PointOFSalePanel-3',icon:'',class:''},
     { path: '/admin/ParameterEntries', title:'Parameter Entries',icon:'',class:''},
     { path: '/admin/ReportPrint', title:'Report Print',icon:'',class:''},


     { path: '/admin/registerappliancesequipment', title:'Appliances/Equipment',icon:'',class:''},
     { path: '/admin/registerasset', title:'Asset',icon:'',class:''},
     { path: '/admin/registerassessories', title:'Assessories',icon:'',class:''},

     { path: '/admin/RegisterOPDBook', title:'Register OPD',icon:'',class:''},
 
     { path: '/admin/RegisterPathologyBookLatest', title:'Bill New',icon:'',class:''},
     { path: '/admin/GenerateStatement', title:'Generate Statement',icon:'',class:''},

     { path: '/admin/GenerateStatementBeforePaid', title:'Generate Statement UnPaid',icon:'',class:''},
     { path: '/admin/GenerateStatementAfterPaid', title:'Generate Statement Paid',icon:'',class:''},

     { path: '/admin/GenerateReport', title:'Generate Report',icon:'',class:''},
     { path: '/admin/GenerateReportBlood', title:'Generate Report Blood',icon:'',class:''},
    { path: '/admin/GenerateReportXray', title:'Generate Report Xray',icon:'',class:''},
    { path: '/admin/GenerateReportUsg', title:'Generate Report Usg',icon:'',class:''},
    { path: '/admin/GenerateReportEcho', title:'Generate Report Echo',icon:'',class:''},


     { path: '/admin/Deletedapplications', title:'Deleted Summary',icon:'',class:''},

     

      { path: '/admin/EditProfile', title: 'Edit Profile', icon: 'nc-diamond', class: '' },

      { path: '/admin/BugFixing', title: 'Bug Fixing', icon: 'nc-diamond', class: '' },
      { path: '/admin/RuleToFollow', title: 'Rule To Follow', icon: 'nc-diamond', class: '' },
      { path: '/admin/RulesOfVisibility', title: 'Visibility Rules', icon: 'nc-diamond', class: '' },
      { path: '/admin/RulesOfBlock', title: 'Block Alert', icon: 'nc-diamond', class: '' },

      { path: '/admin/DueBillApplications', title: 'Due Bills', icon: 'nc-diamond', class: '' },

      { path: '/admin/registersellablecollection', title: 'Sellable', icon: 'nc-diamond', class: '' },
      { path: '/admin/registerstockoutsellable', title: 'Stock Out Sellable', icon: 'nc-diamond', class: '' },
      { path: '/admin/registerstockoutnonsellable', title: 'Stock Out Non Sellable', icon: 'nc-diamond', class: '' },

      { path: '/admin/export-printreportFailed', title: 'Failed To Generate Report', icon: 'nc-diamond', class: '' },

               
      { path: '/admin/payroll-masters', title: 'Payroll-Msters', icon: 'nc-diamond', class: '' },
      { path: '/admin/payroll-setup', title: 'Payroll-Setup', icon: 'nc-diamond', class: '' },
      { path: '/admin/payroll-payment', title: 'Payroll-Payment', icon: 'nc-diamond', class: '' },

      { path: '/admin/payroll-disburse', title: 'Payroll-Disburse', icon: 'nc-diamond', class: '' },
      { path: '/admin/payroll-approvalsettings', title: 'Payroll-Approval', icon: 'nc-diamond', class: '' },
     
       //  { path: '/admin/generate-attendancesheetNEW', title: 'Generate Attendance Device Output', icon: 'nc-diamond', class: '' },
      { path: '/admin/generate-attendancesheetfromDevice', title: 'Generate Attendance Device Output', icon: 'nc-diamond', class: '' },
      
      { path: '/admin/generate-attendanceEMPsheetfromDevice', title: 'Employee Attendance Device Output', icon: 'nc-diamond', class: '' },

      
        { path: '/admin/notification-email', title: 'Notification-Email', icon: 'nc-diamond', class: '' },
        { path: '/admin/notification-sms', title: 'Notification-Sms', icon: 'nc-diamond', class: '' },
        { path: '/admin/notification-bday', title: 'Notification-Bday', icon: 'nc-diamond', class: '' },
        { path: '/admin/notification-paymentreminder', title: 'Notification-Payment Reminder', icon: 'nc-diamond', class: '' },
        

];

@Component({
    moduleId: module.id,
    selector: 'angular-sidebar-cmp',
    templateUrl: 'angular-sidebar.component.html',
    providers: [AppUserService]
})

export class AngularSidebarComponent implements OnChanges {

    @Input() routes: any[];
    public menuItems: any[];
    public showSidebar = false;

    ///////**************** */
    public serviceReturned = false;

    ngOnInit() {
        var value = sessionStorage.getItem("LoggedInRoleId");
        this.setModuleVisibility(value);

        ///////**************** */
        this.showSidebar = false;

        this.modeulePerm.state$.subscribe((state) => {
            if (state && state.permission && state.permission.length > 0) {
                var perms: ModulePermissionElement[] = state.permission;
                this.appitems.forEach(element => {
                    perms.forEach(perm => {
                        if (perm.ModuleName.toLowerCase() == element.label.toLowerCase()) {
                            if (!perm.IsPermitted) {
                                element.hidden = true;
                            }
                        }
                    });
                    if (element.items && element.items.length > 0) {
                        element.items.forEach(subelem => {
                            perms.forEach(perm => {
                                if (perm.ModuleName.toLowerCase() == subelem.label.toLowerCase()) {
                                    if (!perm.IsPermitted) {
                                        subelem.hidden = true;
                                    }
                                }
                            });
                        });
                    }
                });
                this.showSidebar = true;
            }

        });
    }


    ngOnChanges() {
        this.menuItems = this.routes.filter(menuItem => menuItem);
    }

    setModuleVisibility(roleId) {

        this.userService.getAllRoleModuleMAPPINGData(roleId)
            .subscribe(
                (data) => {
                    this.modeulePerm.setModules(data);

                });

    }

    constructor(private router: Router, private modeulePerm: ModulePermissionStore, private userService: AppUserService) { }

    //////////////////////  2222222222222222

    appitems = [

        {
            label: 'Dashboard',
            link: '/admin/dashboard',
        },

        {
            label: 'My Profile',
            items: [
  
               {
                label: 'Edit My Profile',
                link: '/admin/EditProfile',
               },            
    
                   ]
        },
        
        {
            label: 'Organization',
            items: [

                {
                    label: 'Organization Entries',
                    link: '/admin/company',
                    hidden: false
                },
                {
                    label: 'Branch Entries',
                    link: '/admin/branch',
                },

              
            ]
        },

        {
            label: 'Products-Services',
            items: [
             

                {
                    label: 'Product Entries',
                    link: '/admin/productentries',
                },

                {
                    label: 'Product Addon',
                    link: '/admin/productentriesAddon',
                },
                             

            ]
        },

        // {
        //     label: 'Warehousing',
        //     items: [            

        //         {
        //             label: 'Warehouse Entries',
        //             link: '/admin/warehouseMaster',
        //         },
        //         {
        //             label: 'Bin Entries',
        //             link: '/admin/binmaster',
        //         },                

        //     ]
        // },

        
        {
            label: 'Manage User',
            items: [ 

                
                {
                    label: 'User Entries',
                    link: '/admin/userRegistration',
                },
                {
                    label: 'User Permission',
                    link: '/admin/userPermission',
                }, 
                {
                    label: 'User Performance',
                    link: '/admin/generatelist-performance',
                },  
                 
  
            ]
        },

        // {
        //     label: 'Appointments',
        //     items: [ 
             
               
        //         {
        //           label: 'Manage Appointments',
        //           link: '/admin/Registrationappointments',
        //         },  

        //         {
        //             label: 'Queue Manage',
        //             link: '/admin/Registrationqueue',
        //         },                          
      
             
        //     ]
        // },

        // {
        //     label: 'Inquirer Corner',
        //     items: [ 
             
               
        //         {
        //           label: 'Inquirer Entries',
        //           link: '/admin/Registrationinquirer',
        //         },  
                          
      
        //     ]
        // },



        {
            label: 'Lead Source',
            items: [ 
             
               
                {
                  label: 'Website Download',
                  link: '/admin/Page-UnderDevelopment',
                },  
                {
                    label: 'Web Research',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Chat',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Email',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Public Relation',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Online Store',
                    link: '/admin/Page-UnderDevelopment',
                },   
                {
                    label: 'Social Media',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Referral',
                    link: '/admin/Page-UnderDevelopment',
                },
                {
                    label: 'Cold Calling',
                    link: '/admin/Page-UnderDevelopment',
                },
                {
                    label: 'Advertisement',
                    link: '/admin/Page-UnderDevelopment',
                },

                    
      
            ]
        },

        {
            label: 'Lead Automation',
            items: [ 
             
               
                {
                  label: 'Website Download',
                  link: '/admin/Page-UnderDevelopment',
                },  
                {
                    label: 'Web Research',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Chat',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Email',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Public Relation',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Online Store',
                    link: '/admin/Page-UnderDevelopment',
                },   
                {
                    label: 'Social Media',
                    link: '/admin/Page-UnderDevelopment',
                }, 
                {
                    label: 'Referral',
                    link: '/admin/Page-UnderDevelopment',
                },
                {
                    label: 'Cold Calling',
                    link: '/admin/Page-UnderDevelopment',
                },
                {
                    label: 'Advertisement',
                    link: '/admin/Page-UnderDevelopment',
                },

                    
      
            ]
        },
     
        {
            label: 'Salesforce Accelerator',
            items: [ 
             
               
                {
                    label: 'Meeting/Appointments',
                    link: '/admin/Registrationappointments',
                },  

                {
                      label: 'Activity/Events',
                      link: '/admin/Registrationactivity',
                },   

                {
                    label: 'Inquirer Manage',
                    link: '/admin/Registrationinquirer',
                },  
                 

                                          
      
            ]
        },
                
        {
            label: 'Visitor Registration',
            items: [

                ///  { path: '/admin/Registrationvisitor', title:'Registration Visitor',icon:'',class:''},
                // {
                //     label: 'Visitor Entries',
                //     link: '/admin/Registrationvisitor',
                // },

                {
                    label: 'Visitor Entries',
                    link: '/admin/Registrationapplication',
                },

                {
                    label: 'Feedback Statistics',
                    link: '/admin/generatelist-feedback',
                },

                {
                    label: 'Prospective Statistics',
                    link: '/admin/generate-potentiallist',
                },
               
                {
                  label: 'New/Healing Status',
                  link: '/admin/generatelist-new',
                },
              
                {
                    label: 'Attendance For All',
                    link: '/admin/generate-attendancesheet',
                   
                },

                {
                    label: 'Attendance For New',
                    link: '/admin/generate-attendancesheetNEW',                    
                   
                },

                {
                    label: 'Biometric Attendance',
                    link: '/admin/generate-attendancesheetfromDevice',
                },              
              
                {
                    label: 'RedeemPts Statistics',
                    link: '/admin/requisitionlist-redeempoints',
                },
  
   
  
            ]
        },

        {
            label: 'Deal Pipeline',
            items: [ 
             
               
                {
                    label: 'Manage Prospective',
                    link: '/admin/generate-potentiallist',
                },               

                {
                    label: 'Customer Entries',
                    link: '/admin/Registrationcustomer',
                },  
                
                {
                    label: 'Billing Address',
                    link: '/admin/consignormaster',
                },
    
                {
                    label: 'Shipping Address',
                    link: '/admin/consigneemaster',
                },      

                                          
      
            ]
        },

        {
            label: 'Sales Forecasting',
            items: [ 
             
               
                {
                  label: 'Upselling',
                  link: '/admin/Page-UnderDevelopment',
                },  
                {
                    label: 'Cross Selling',
                    link: '/admin/Page-UnderDevelopment',
                },  
                {
                    label: 'Multi Variable',
                    link: '/admin/Page-UnderDevelopment',
                },  
                          
      
            ]
        },
     
     
        // {
        //     label: 'Customer Registration',
        //     items: [ 
             
               
        //         {
        //             label: 'Customer Entries',
        //             link: '/admin/Registrationcustomer',
        //         },  
                
        //         {
        //             label: 'Billing Address',
        //             link: '/admin/consignormaster',
        //           },
    
        //           {
        //             label: 'Shipping Address',
        //             link: '/admin/consigneemaster',
        //           },                         
      
               
        //     ]
        // },
     
        {
          label: 'Ledger Entries',
          items: [              

              {
                  label: 'Debtor Entries',
                  link: '/admin/Registrationdebtor',
              },

              {
                  label: 'Creditor Entries',
                  link: '/admin/Registrationcreditor',
              },

            //   {
            //       label: 'Employee Entries',
            //       link: '/admin/Registrationemployee',
            //   },

            //   {
            //     label: 'Doctor Entries',
            //     link: '/admin/Registrationdoctor',
            //   },

              {
                label: 'Associate Entries',
                link: '/admin/Registrationassociate',
              },

              {
                label: 'Bank Entries',
                link: '/admin/Registrationbank',
              },              
    

              {
                  label: 'Expense Head Entries',
                  link: '/admin/Registrationexpenditure',
              },

              {
                label: 'Income Head Entries',
                link: '/admin/Registrationincome',
              },

            


          ]
        },

        {
          label: 'Asset-Inventory',
          items: [

            {
                label: 'Warehouse Entries',
                link: '/admin/warehouseMaster',
            },
            {
                label: 'Bin Entries',
                link: '/admin/binmaster',
            },   


               // {
              //   label: 'Day Book',
             //   link: '/admin/RegisterDayBook',
            // },


                    // ------------- KW SALES BOOK ----------
                   // {
                  //   label: 'Retail Sales',
                 //   link: '/admin/RegisterSalesBook',
                // },
               // {
              //   label: 'Wholesale Sales',
             //   link: '/admin/RegisterSalesWholeSale',
            // },

            
                // ------------- KW PURCHASE BOOK ----------
               // {
              //   label: 'Purchase Book',
             //   link: '/admin/RegisterPurchaseBook',
            // },

            {
                label: 'Purchase Sellable',
                link: '/admin/registersellablecollection',
            },
            {
                label: 'Purchase Appliances',
                link: '/admin/registerappliancesequipment',
            },
            {
                label: 'Purchase Asset',
                link: '/admin/registerasset',
            },
            {
                label: 'Purchase Accessories',
                link: '/admin/registerassessories',
            },

            {
              label: 'Purchase Payment',
              link: '/admin/PurchaseBookPayment',
            },

               // {
              //     label: 'Product Requisition',
             //     link: '/admin/stockproductrequisition',
            // },

            {
                label: 'Product Requisition',
                link: '/admin/ProductRequisiton',
            },

            {
                label: 'Stock In Data',
                link: '/admin/stockin',
            },
           
            {
                label: 'Stock Out Data',
                link: '/admin/stockout',
            },
           
            {
                label: 'Stock In Hand Data',
                link: '/admin/producttransition',
            },


            // {
            //     label: 'Stock Out Sellable',
            //     link: '/admin/registerstockoutsellable',
            // },

            // {
            //     label: 'Stock Out NonSellable',
            //     link: '/admin/registerstockoutnonsellable',
            // },

            //   {
            //       label: 'Only Sales',
            //      link: '/admin/RegisterSalesBook',
            //   },


              /////// PointOFSalePanel-1  -- WHOLE SALE PANEL
            //  {
            //     label: 'PointOFSalePanel-2',
            //     link: '/admin/PointOFSalePanel-2',
            //   },

            // { path: '/admin/registerstockoutsellable', title: 'Due Bills', icon: 'nc-diamond', class: '' },
            // { path: '/admin/registerstockoutnonsellable', title: 'Due Bills', icon: 'nc-diamond', class: '' },
        
           

          ]
        },


            //  PointOFSalePanel-1 ----------- RETAIL SALE PANEL 

        {
            label: 'Sales-Distribution',
            items: [ 

                //    { path: '/admin/registration-booking', title: 'Booking Panel', icon: 'nc-diamond', class: '' },

                // {
                //     label: 'PointOFBooking',
                //     link: '/admin/registration-booking',
                // },

                {
                    label: 'PointOFSalePanel-1',
                    link: '/admin/PointOFSalePanel-1',
                },

                {
                    label: 'PointOFSalePanel-2',
                    link: '/admin/PointOFSalePanel-2',
                },

               
                
            ]
        },

        {

            label: 'Day Book',
            items: [ 

    
              {
                label: 'Money Receive',
                link: '/admin/DaybookMoneyReceive',
              },

              {
                label: 'Payment Disburse',
                link: '/admin/DaybookPaymentDisburse',
              },   

              {
                label: 'Daybook Statistics',
                link: '/admin/DaybookStatistics',
              },   
                             
              
    
            ]
            
        },
        

        // {
        //     label: 'Commission',
        //     items: [             

        //         {
        //             label: 'UnPaid Commission',
        //             link: '/admin/GenerateStatementBeforePaid',
        //         }, 
        //         {
        //             label: 'Paid Commission',
        //             link: '/admin/GenerateStatementAfterPaid',
        //         },  
        //         {
        //             label: 'Print Commission',
        //             link: '/admin/GenerateStatement',
        //         },           
                      
        //         {
        //             label: 'Slab Mapping',
        //             link: '/admin/SlabMapping',
        //         }, 
        //         {
        //             label: 'Slab Entries',
        //             link: '/admin/Slabentries',
        //         },            
                
             
                

        //     ]
        // },
  
        // {
        //     label: 'Order Statistics',
        //     items: [

        //         {
        //             label: 'Pending Orders',
        //             link: '/admin/orderpending',
        //         },

        //         {
        //             label: 'Placed[Unshipped] Orders',
        //             link: '/admin/orderunshipped',
        //         },

        //         {
        //             label: 'Dispatched Orders',
        //             link: '/admin/orderdispatched',
        //         },
        //         {
        //             label: 'Delivered Orders',
        //             link: '/admin/orderdelivered',
        //         },

        //         {
        //             label: 'Cancelled Orders',
        //             link: '/admin/ordercancelled',
        //         },


        //     ]
        // },      

        {
            label: 'Payroll System',
            items: [

          
                {
                    label: 'Payroll-Msters',
                    link: '/admin/payroll-masters',
                },
                {
                    label: 'Employee Registration',
                    link: '/admin/payroll-setup',
                },

                {
                    label: 'Payroll-Disburse',
                    link: '/admin/payroll-disburse',
                },

                {
                    label: 'Payroll-Payment',
                    link: '/admin/payroll-payment',
                },

                // {
                //     label: 'Payroll-Approval',
                //     link: '/admin/payroll-approvalsettings',
                // },
          

            ]
        },


        // {
        //     label: 'Quick Reference',
        //     items: [

        //         {
        //         label: 'Product Report',
        //         link: '/admin/reportproduct',
        //         },

        //         {
        //             label: 'Inwards Report',
        //             link: '/admin/reportstockinwards',
        //         },

        //         {
        //             label: 'Outwards Report',
        //             link: '/admin/reportstockoutwards',
        //         },

        //         {
        //             label: 'Stock Report',
        //             link: '/admin/reportproductinhand',
        //         },
              
             
        //     ]
        // },
        
        {
            label: 'Smart Performance',
            items: [             

                {
                    label: 'Deleted Summary',
                    link: '/admin/Deletedapplications',
                },

                {
                    label: 'Due Bills Summary',
                    link: '/admin/DueBillApplications',
                }, 


            ]
        },

        {
            label: 'Rules-Fixing',
            items: [ 

               {
                    label: 'Setup Bug Fixing',
                    link: '/admin/BugFixing',
               },

               {
                   label: 'Ledger Privacy Setup',
                   link: '/admin/Ledgerprivacysetup',
               },
 
  
                {
                  label: 'Access Rules',
                  link: '/admin/AccessRules',
                }, 

  
                // {
                //   label: 'Visibility Rules',
                //   link: '/admin/RulesOfVisibility',
                // },

                 
                {
                    label: 'Coupon Master',
                    link: '/admin/master-coupon',
                },
                {
                    label: 'Earning Pts Master',
                    link: '/admin/master-earningpoints',
                },

                {
                    label: 'Redeem Pts Master',
                    link: '/admin/master-redeempoints',
                },                              
  
            ]
        },

        // {
        //     label: 'Maintenance Process',
        //     items: [
                
        //         {
        //             label: 'Maintenance Action',
        //             link: '/admin/applicationmaintenance',
        //         },
                
        //     ]
        // },


        // {
        //     label: 'Notification',
        //     items: [
                
        //         {
        //             label: 'Email',
        //             link: '/admin/notification-email',
        //         },

        //         {
        //             label: 'Sms',
        //             link: '/admin/notification-sms',
        //         },

        //         // {
        //         //     label: 'Bday',
        //         //     link: '/admin/notification-bday',
        //         // },

        //         // {
        //         //     label: 'Payment Reminder',
        //         //     link: '/admin/notification-paymentreminder',
        //         // },
                
        //     ]
        // },
        
        // { path: '/admin/notification-email', title: 'Notification-Email', icon: 'nc-diamond', class: '' },
        // { path: '/admin/notification-sms', title: 'Notification-Sms', icon: 'nc-diamond', class: '' },
        // { path: '/admin/notification-bday', title: 'Notification-Bday', icon: 'nc-diamond', class: '' },
        // { path: '/admin/notification-paymentreminder', title: 'Notification-Payment Reminder', icon: 'nc-diamond', class: '' },
        



        {
            label: 'Item 4',
            link: '/item-4',
            icon: 'star_rate',
            hidden: true
        }


    ];

    config = {
        paddingAtStart: true,
        classname: 'my-custom-class',
        // listBackgroundColor: 'rgb(208, 241, 239)',
        fontColor: 'rgb(0, 0, 0)',
        // backgroundColor: 'rgb(208, 241, 239)',
        //selectedListFontColor: 'red',
    };

    selectedItem(data) {
        this.router.navigate([data.link]);
        console.log(data);
    }
}
