import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {BranchElement} from "../../Models/ApplicationModels/BranchModel";

import { environment } from '../../../environments/environment';
//   private apiUrl = environment.apiUrl;

@Injectable()
export class BranchService {

     ////import { environment } from '../../../environments/environment';
   private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }



  /////////////////////////  ------------  GET ALL BRANCH ------------
  getAllBranchData() {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/BranchMaster/GetAllBranch",httpOptions);
  }



  ///////////////////////  ----------- GET ALL BRANCH BY BRANCH_ID -----------
  getAllBranchDataByBranchId(branchId:number) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/BranchMaster/GetAllBranchById?branchid="+branchId,httpOptions);
  }



   ///////////////////////  ----------- GET ALL BRANCH BY COMP_ID -----------
  getAllBranchDataByCompId(compId:number) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/BranchMaster/GetAllBranchByCompId?compid="+compId,httpOptions);
  }



  /////////////////////////////  ------------- CURD OPERATIONS -----------------
  insertBranchData(branch:BranchElement) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  return this.http.post(""+this.apiUrl+"/BranchMaster/AddBranch",
  JSON.stringify(branch),
   httpOptions
  )
  }




}
