
        //  http://rdcadminapis.integraff.com

         // //  // return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUser",httpOptions);

       // APP  http://localhost:63604/
      // APP  http://192.168.0.3:63604/

     // API  http://adminapinew.thenugabest.com/
    // API  http://adminapinew.thenugabest.com/

   //  localhost      192.168.0.33

import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
//import {BranchElement} from "../../Models/ApplicationModels/BranchModel";
import {AppUserElement} from "../../Models/ApplicationModels/AppUserModel";

import { environment } from '../../../environments/environment';
//   private apiUrl = environment.apiUrl;

@Injectable()
export class AppUserService {

    ////import { environment } from '../../../environments/environment';
   private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  /////////////////////////  ------------  GET ALL ------------
  getAllAppUserData() {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  
   
  // return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUser",httpOptions);

  return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUser",httpOptions);

  }

  ///////////////////////  ----------- GET ALL BY ID -----------

  getAllAppUserByIdData(userid:number) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUserById?UserId="+userid,httpOptions);
  }

  getAllAppUserByUserPassData( usernames:string, passwords:string  ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUserByUserPass?username="+usernames+"&password="+passwords,httpOptions);
  }


  getAllRoleModuleMAPPINGData( userroleid:number ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllRoleModuleMAPPING?UserRoleId="+userroleid,httpOptions);
  }


  getAllRoleModuleMAPPINGUpdateData(pid:number, isp:number ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllRoleModuleMAPPINGUpdate?privilegeid="+pid+"&ispermitted="+isp,httpOptions);
  }

  getAllSKUHeaderMAPPINGData( itemVariationid:number ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllSKUHeaderMAPPING?ItemVariationId="+itemVariationid,httpOptions);
  }

  getAllSKUHeaderMAPPINGUpdateData(pid:number, isp:number ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllSKUHeaderMAPPINGUpdate?privilegeid="+pid+"ispermitted="+isp,httpOptions);
  }

  saveSKUHeaderMAPPINGUpdateData(data) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.post<any>(""+this.apiUrl+"/AppUserMaster/UploadSKUHEADERPermission",data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getAllRoleModuleMAPPINGResetData( urid:number ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllRoleModuleMAPPING?userroleId="+urid,httpOptions);
  }

  /////////////////////////////  ------------- CURD -----------------

  addAppUserData(appuser:AppUserElement) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  return this.http.post(""+this.apiUrl+"/AppUserMaster/AddAppUser",
  JSON.stringify(appuser),
   httpOptions
  )
  }

  getAllUserCategory() {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/CommonWeb/GetAllAppUserCategory",httpOptions);
  }

  saveRoleModuleMAPPINGUpdateData(data) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.post<any>(""+this.apiUrl+"/AppUserMaster/UploadComponentPermission",data, {
      reportProgress: true,
      observe: 'events'
    });
  }
  
  getAllUserCategoryLatest() {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };

  //return this.http.get("http://soccersignupapis.integraff.com/api/CommonWeb/GetAllAppUserCategory",httpOptions);
  return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUserCategoryLatest",httpOptions);

  }

  getAllAppUserMasterByConsignorIdLatest(createdbys:number) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUserMasterByConsignorIdLatest?CreatedBy="+createdbys,httpOptions);
  }
    
  getAllAppUserByAdmissionMasterIdData(mids:number) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUserByAdmissionMasterId?AdmissionMasterId="+mids,httpOptions);
  //return this.http.get("http://localhost:49587/api/AppUserMaster/GetAllAppUserByAdmissionMasterId?AdmissionMasterId="+mids,httpOptions);



  }
  
  getAllUsersOFBranchData( mids:number ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllUsersOFBranch?UserId="+mids,httpOptions);
  //return this.http.get("http://localhost:49587/api/AppUserMaster/GetAllAppUserByAdmissionMasterId?AdmissionMasterId="+mids,httpOptions);



  }

  getAllAppUserByNameData( names:string ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  
    return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUserByName?Name="+names,httpOptions);
  //return this.http.get("http://localhost:49587/api/AppUserMaster/GetAllAppUserByAdmissionMasterId?AdmissionMasterId="+mids,httpOptions);



  }

  GetAllAppUserMasterSessionResetData( CreatedBys:number, SessionIsActives:number  ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
  
  return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllAppUserMasterSessionReset?CreatedBy="+CreatedBys+"&SessionIsActive="+SessionIsActives,httpOptions);
  //  return this.http.get(""+this.apiUrl+"/AppUserMaster/GetAllSKUHeaderMAPPINGUpdate?privilegeid="+pid+"ispermitted="+isp,httpOptions);

  }

    
  UploadMappingAdmissionMasterIdUserroleIdData(data) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.post<any>(""+this.apiUrl+"/AppUserMaster/UploadMappingAdmissionMasterIdUserroleId",data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  
  getAllAdmissionMasterIdUserroleIdMappingData( roleid:number , midcategory:number ) {
    var httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
          'key': 'x-api-key',
          'value': 'NNctr6Tjrw9794gFXf3fi6zWBZ78j6Gv3UCb3y0x',

      })
  };
    return this.http.get(""+this.apiUrl+"/AppUserMaster/getAllAdmissionMasterIdUserroleIdMapping?UserRoleId="+roleid+"&Category="+midcategory,httpOptions);
  }


}
