import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminComponent } from './+Admin/admin.component';
import { AngularSidebarModule } from './angular-sidebar/angular-sidebar.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { fakeBackendProvider, JwtInterceptor, ErrorInterceptor } from './Interceptors';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { MatTabsModule } from '@angular/material';
import { ModulePermissionStore } from './shared/store/modulePerm.store';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgxBarcodeModule } from "ngx-barcode";
  //import { RequisitionComponent } from './requisition/requisition.component';

import { ExportAsModule } from 'ngx-export-as';
  // import { ExportPrintreportComponent } from './export-printreport/export-printreport.component';

   // import { ExportPrintreportxrayComponent } from './Admin/export-printreportxray/export-printreportxray.component';
  // import { ExportPrintreportbloodComponent } from './Admin/export-printreportblood/export-printreportblood.component';
 // import { ExportPrintreportusgComponent } from './Admin/export-printreportusg/export-printreportusg.component';
// import { ExportPrintreportechoComponent } from './Admin/export-printreportecho/export-printreportecho.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AdminLoginComponent,
    // RequisitionComponent,
   // ExportPrintreportComponent,

       //ExportPrintreportxrayComponent,
      //ExportPrintreportbloodComponent,
     //ExportPrintreportusgComponent,
    //ExportPrintreportechoComponent,

  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    HttpClientModule,
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    AngularSidebarModule,
    FormsModule,
    NgMaterialMultilevelMenuModule,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatTabsModule,
    NgxBarcodeModule,
    ExportAsModule,

  ],
  providers:
   [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    //BranchService,
    // provider used to create fake backend
    fakeBackendProvider,ModulePermissionStore


   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
