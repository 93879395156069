import { Injectable } from '@angular/core';
import { Store } from './store';
import { ModulePermissionState } from '../Models/ModulePermission.model';

@Injectable()
export class ModulePermissionStore extends Store<ModulePermissionState> {
    constructor() {
        super(new ModulePermissionState());
    }

    setModules(data): void {
        this.setState({
            ...this.state,
            permission: data
        });
    }

}