import { Routes } from '@angular/router';

//import { AdminComponent } from './+Admin/admin.component';
import { AdminComponent } from './+Admin/admin.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AuthGuard } from './Guards';
// import { ExportDataComponent } from './export-data/export-data.component';
import { ExportDataComponent } from './+Admin/export-data/export-data.component';
export const AppRoutes: Routes = [

  // { path: 'export-data', component:ExportDataComponent },
  {
    path: '',
    //redirectTo: 'admin',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    component: AdminComponent,
     children: [
      {
    //path: '',
    //loadChildren: () => import('./+Admin/admin.module').then(mod => mod.AdminModule)
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./+Admin/admin.module').then(mod => mod.AdminModule)
}]
},
{ path: 'login', component: AdminLoginComponent },
  {
    path: '**',
    redirectTo: 'login'
  }

    // {
    //   path: 'admin',
    //   loadChildren: () => import('./+Admin/admin.module').then(mod => mod.AdminModule)
    // },

]
